import React from 'react';

import { CarouselRow, CarouselItem } from 'components/common/carousel';
import Image from 'components/common/image';
import ResponsiveBox from 'components/common/responsive-box';
import { mobile, tablet, desktop } from 'utils/media';

import { ThumbnailBreakpoints } from './helpers';
import { ProductCarouselProps } from './types';

// @TODO: Rename this to avoid confusion. I think it's carousel with photos of a product
export default function ProductCarousel({
  product,
  showThumbnails = true,
  dottedThumbnailsOnly = false,
  dottedThumbnailsBreakpoint = ThumbnailBreakpoints.Mobile,
  scrollBarReveal = false,
}: ProductCarouselProps) {
  return product.photos ? (
    <CarouselRow
      key={product.sku}
      arrowLayout="overlay"
      inline
      {...(showThumbnails && {
        thumbnails: product.photos.map((photo, index) => (
          <ResponsiveBox rounded size="small" key={index} aspectRatio={1}>
            <Image
              loader="cloudinary"
              src={photo}
              aspectRatio={1}
              sizes={`113px`}
              alt=""
            />
          </ResponsiveBox>
        )),
      })}
      dottedThumbnailsOnly={dottedThumbnailsOnly}
      dottedThumbnailsBreakpoint={dottedThumbnailsBreakpoint}
      scrollBarReveal={scrollBarReveal}
    >
      {product.photos.map((photo, index) => (
        <CarouselItem
          inline
          size="full"
          key={photo?._key ?? `carousel-item-${index}`}
        >
          <ResponsiveBox aspectRatio={1}>
            <Image
              loader="cloudinary"
              src={photo}
              aspectRatio={1}
              sizes={`${mobile} 100vw, ${tablet} 50vw, ${desktop} 590px`}
              loading={index === 0 ? 'eager' : 'lazy'}
              alt=""
            />
          </ResponsiveBox>
        </CarouselItem>
      ))}
    </CarouselRow>
  ) : null;
}
