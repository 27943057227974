import React from 'react';

import ColorSelect from 'components/common/forms/color-select';

import {
  Breakpoint,
  MoreColors,
  ColorSelectOptions,
  ColorSelectRow,
} from './styles';
import { IColorSelectorProps } from './types';

const ColorSelector: React.VFC<IColorSelectorProps> = ({
  label,
  colors = [],
  size = 'large',
  limit,
}) => {
  if (limit) {
    return (
      <ColorSelectRow>
        {label && <span>{label}</span>}
        {Object.entries(limit).map(([breakpoint, limitNumber]) => {
          const limitedColors = colors.slice(0, limitNumber);

          return (
            <Breakpoint key={breakpoint} media={breakpoint}>
              <ColorSelectOptions hasLabel={!!label}>
                {limitedColors.map(
                  ({ color, isSelected = false, url }: Sproutl.IColorProps) => (
                    <ColorSelect
                      color={color}
                      href={url}
                      selected={isSelected}
                      size={size}
                      key={color}
                    />
                  ),
                )}
                {limit && colors.length > limitNumber ? (
                  <MoreColors>{`+${colors.length - limitNumber}`}</MoreColors>
                ) : null}
              </ColorSelectOptions>
            </Breakpoint>
          );
        })}
      </ColorSelectRow>
    );
  }

  return (
    <ColorSelectRow>
      {label && <span>{label}</span>}
      {colors.length ? (
        <ColorSelectOptions hasLabel={!!label}>
          {colors.map(
            ({ color, isSelected = false, url }: Sproutl.IColorProps) => (
              <ColorSelect
                color={color}
                href={url}
                selected={isSelected}
                size={size}
                key={color}
              />
            ),
          )}
        </ColorSelectOptions>
      ) : null}
    </ColorSelectRow>
  );
};

export default ColorSelector;
