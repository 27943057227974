import React, { useEffect, useMemo, useState } from 'react';

import {
  MarginBottomOptions,
  getAttributesLabel,
} from 'components/common/attributes-label/helpers';
import { AttributesLabel } from 'components/common/attributes-label/styles';
import Button from 'components/common/button';
import { useCookieAccepted } from 'components/common/cookie-banner/utils';
import { Close } from 'components/common/icons';
import Image from 'components/common/image';
import ModalNotification from 'components/common/modal-notification';
import {
  HeadingText,
  HeadingWrapper,
  CloseButton,
} from 'components/common/modal-notification/styles';
import ResponsiveBox from 'components/common/responsive-box';
import SiteLink from 'components/common/site-link';
import formatPrice from 'utils/formatPrice';
import { getItemItemsString } from 'utils/helpers';

import {
  ProductDetails,
  ProductImageWrapper,
  ProductDetailsPrice,
  ProductDetailsTitle,
  ProductWrapper,
  // VariantDetails,
  Wrapper,
  StyledContainer,
} from './styles';
import { IAddToNotification, IProductAttributesRawConfig } from './types';

const AddToNotification = ({
  isOpen = false,
  productDetails = [],
  persist = false,
}: IAddToNotification) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const productCount = productDetails.length;
  const productAttributesRawConfig = useMemo(
    () =>
      productDetails.reduce((accConfigObj, { attributesRaw, sku }) => {
        // { sku: attributesRaw }
        if (attributesRaw && sku) {
          return {
            ...accConfigObj,
            [sku]: getAttributesLabel(attributesRaw),
          };
        }
        return accConfigObj;
      }, {} as IProductAttributesRawConfig),
    [productDetails],
  );

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (!persist) {
      setTimeout(() => {
        setIsModalOpen(false);
      }, 4000);
    }
  }, [isOpen, persist]);

  const totalQty = useMemo(
    () => productDetails.reduce((qty, item) => qty + item.quantity, 0),
    [productDetails],
  );

  const hasCookieAccepted = useCookieAccepted();

  return (
    <ModalNotification
      isBespokeModal
      isOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <StyledContainer noGutter>
        <Wrapper
          cookieAccepted={hasCookieAccepted}
          isOpen={isModalOpen}
          data-testid="addToNotification"
        >
          <HeadingWrapper>
            <HeadingText>
              {`Added ${totalQty} ${getItemItemsString(
                totalQty,
                false,
              )} to basket`}
            </HeadingText>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <CloseButton type="button" onClick={() => setIsModalOpen(false)}>
              <Close height={16} width={16} />
            </CloseButton>
          </HeadingWrapper>
          <ProductWrapper productCount={productCount}>
            {productDetails.map(({ src, title, price, sku }, index) => (
              <ProductDetails
                key={`${title}-${index}`}
                productCount={productCount}
              >
                <>
                  {src ? (
                    <ProductImageWrapper productCount={productCount}>
                      <ResponsiveBox size="small" aspectRatio={1} rounded>
                        <Image
                          loader="cloudinary"
                          src={src}
                          aspectRatio={1}
                          sizes="150px"
                          alt={title}
                        />
                      </ResponsiveBox>
                    </ProductImageWrapper>
                  ) : null}
                  {productCount <= 2 && (
                    <div>
                      <ProductDetailsTitle marginBottom={10}>
                        {title}
                      </ProductDetailsTitle>
                      {sku && productAttributesRawConfig[sku] && (
                        <AttributesLabel
                          stack
                          marginBottom={MarginBottomOptions.Small}
                        >
                          {productAttributesRawConfig[sku]}
                        </AttributesLabel>
                      )}
                      <ProductDetailsPrice>
                        {formatPrice(price)}
                      </ProductDetailsPrice>
                    </div>
                  )}
                </>
              </ProductDetails>
            ))}
          </ProductWrapper>
          <SiteLink href="/basket">
            <Button
              as="div"
              width="full"
              variant="primary"
              data-testid="addToNotification-checkout"
            >
              Checkout
            </Button>
          </SiteLink>
        </Wrapper>
      </StyledContainer>
    </ModalNotification>
  );
};

export default AddToNotification;
