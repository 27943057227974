import styled from 'styled-components';

import { Container as QuantitySelectContainer } from 'components/common/forms/quantity-select/styles';
import { Heading4 } from 'components/common/text';
import { mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

export const HeaderHeadlineRow = styled.div({
  marginBottom: 20,
});

export const PartnerName = styled.p`
  margin-bottom: 20px;
  margin-top: 0;
`;

export const NutshellsMobileRow = styled.div`
  background-color: ${theme.colors.white};
  display: none;
  margin: 0 -20px;
  position: relative;
  margin-bottom: -1px;
  padding: 20px 0;

  ${mobile} {
    display: block;
  }
`;

interface StickyContentProps {
  cookieAccepted: boolean;
}

export const StickyContent = styled.div<StickyContentProps>`
  padding: ${(props) => (props.cookieAccepted ? `20px 0` : `20px 0 120px 0`)};

  ${QuantitySelectContainer} {
    margin-top: var(--space-2xs);
  }
`;

export const DeliveryInfoFlexRow = styled.div`
  ${tablet} {
    display: flex;
  }

  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;

  p:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  div:last-child {
    padding-top: var(--space-3xs);
    text-align: right;
  }
`;

export const HeaderActionsRow = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,

  '> *': {
    marginBottom: 15,
  },

  [mobile]: {
    '> *:nth-child(2)': {
      order: 3,
    },
  },

  [tablet]: {
    flexDirection: 'row',
    marginBottom: 15,
    '> *': {
      marginRight: 15,
      marginBottom: 0,
    },
  },

  '> *:last-child': {
    marginRight: 0,
  },
});

export const PriceLabel = styled(Heading4)({ color: theme.colors.irisPurple });

export const GiftMessage = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: var(--space-xs);
  }

  p {
    line-height: 1.2;
  }

  span {
    font-family: ${theme.fonts.heading};
  }
`;
