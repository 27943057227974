import styled from 'styled-components';

import theme from 'utils/theme';

export const NutshellRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -10px;
`;

export const NutshellCol = styled.div`
  color: ${theme.colors.purple};
  font-weight: ${theme.weights.headingBold};
  font-family: ${theme.fonts.heading};
  text-align: center;
  margin: 0 var(--space-2xs);
  width: 110px;
`;

export const NutshellIconWrapper = styled.div`
  margin-bottom: var(--space-2xs);
`;
