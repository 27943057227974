import { debounce, throttle } from 'lodash';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { Chevron } from 'components/common/icons';
import { ThumbnailBreakpoints } from 'components/layouts/product/carousel/helpers';

import {
  ArrowButton,
  CarouselRowContainer,
  CarouselItem,
  CarouselRowInner,
  ThumbnailLink,
  ThumbnailsGrid,
  ThumbnailContent,
  ThumbnailDot,
  ArrowWrapper,
  thumbnailWidth,
} from './styles';
import { CarouselRowProps } from './types';

export { CarouselItem };

export function CarouselRow({
  arrowLayout,
  className,
  inline,
  snap,
  children,
  thumbnails = [],
  dottedThumbnailsOnly = false,
  dottedThumbnailsBreakpoint = ThumbnailBreakpoints.Mobile,
  scrollBarReveal = false,
}: CarouselRowProps) {
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(0);
  const [
    thumbnailTrackAutoScrollDisabled,
    setThumbnailTrackAutoScrollDisabled,
  ] = useState<boolean>(false);

  const [availableArrows, setAvailableArrows] = useState({
    left: false,
    right: false,
  });

  const slidesTrackRef = useRef<HTMLDivElement>(null);
  const thumbnailsTrackRef = useRef<HTMLDivElement>(null);
  const activeThumbnailRef = useRef<HTMLDivElement>();
  const { ref: inViewRef, inView: activeThumbnailVisible } = useInView({
    threshold: 1,
  });

  const setActiveThumbnailRefs = useCallback(
    (node) => {
      activeThumbnailRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const arrowJump = useCallback((direction: 'left' | 'right') => {
    if (slidesTrackRef.current) {
      const width = slidesTrackRef.current.offsetWidth;
      slidesTrackRef.current.scrollBy({
        left: direction === 'right' ? width : width - width * 2,
        behavior: 'smooth',
      });
    }
  }, []);

  const thumbnailJump = useCallback((index: number) => {
    if (slidesTrackRef.current) {
      const width = slidesTrackRef.current.offsetWidth;

      slidesTrackRef.current.scrollTo({
        left: index * width,
        behavior: 'smooth',
      });
    }
  }, []);

  const determineAvailableArrows = () => {
    if (!slidesTrackRef.current) return;

    setAvailableArrows({
      left: slidesTrackRef.current.scrollLeft > 0,
      right:
        slidesTrackRef.current.scrollLeft <
        slidesTrackRef.current.scrollWidth - slidesTrackRef.current.offsetWidth,
    });
  };

  const handleTrackScroll = throttle(() => {
    setThumbnailTrackAutoScrollDisabled(false);
    if (slidesTrackRef.current) {
      setActiveItemIndex(
        Math.round(
          slidesTrackRef.current.scrollLeft /
            slidesTrackRef.current.offsetWidth,
        ),
      );
    }

    determineAvailableArrows();
  }, 100);

  const scrollThumbnailsTrack = debounce(
    (left: number) => {
      thumbnailsTrackRef?.current?.scrollTo({
        left: left,
        behavior: 'smooth',
      });
    },
    1000,
    {
      trailing: false,
      leading: true,
    },
  );

  useEffect(() => {
    if (thumbnails.length) {
      if (
        !activeThumbnailVisible &&
        thumbnailsTrackRef.current &&
        activeThumbnailRef.current &&
        !thumbnailTrackAutoScrollDisabled
      ) {
        const { clientWidth, scrollLeft } = thumbnailsTrackRef.current;

        const thumbnailsTrackRelWidth = clientWidth + scrollLeft;

        const { offsetLeft, offsetWidth } = activeThumbnailRef.current;

        const visiblePartWidth = thumbnailsTrackRelWidth - offsetLeft;
        const obscuredPartWidth = offsetWidth - visiblePartWidth;

        scrollThumbnailsTrack(scrollLeft + obscuredPartWidth + thumbnailWidth);
      }
    }
  }, [
    activeItemIndex,
    activeThumbnailVisible,
    scrollThumbnailsTrack,
    thumbnailTrackAutoScrollDisabled,
    thumbnails.length,
  ]);

  useEffect(() => {
    determineAvailableArrows();
  }, []);

  return (
    <>
      {arrowLayout === 'top' &&
      (availableArrows.left || availableArrows.right) ? (
        <ArrowWrapper>
          <ArrowButton
            type="button"
            onClick={() => arrowJump('left')}
            disabled={!availableArrows.left}
            arrowLayout={arrowLayout}
            aria-label="Previous image"
          >
            <Chevron />
          </ArrowButton>
          <ArrowButton
            type="button"
            onClick={() => arrowJump('right')}
            disabled={!availableArrows.right}
            arrowLayout={arrowLayout}
            aria-label="Next image"
          >
            <Chevron />
          </ArrowButton>
        </ArrowWrapper>
      ) : null}
      <CarouselRowContainer className={className}>
        {arrowLayout &&
        arrowLayout !== 'top' &&
        (availableArrows.left || availableArrows.right) ? (
          <ArrowButton
            type="button"
            onClick={() => arrowJump('left')}
            disabled={!availableArrows.left}
            arrowLayout={arrowLayout}
            aria-label="Previous image"
          >
            <Chevron />
          </ArrowButton>
        ) : null}
        <CarouselRowInner
          onScroll={arrowLayout ? handleTrackScroll : undefined}
          ref={slidesTrackRef}
          inline={inline}
          snap={snap}
          scrollBarReveal={scrollBarReveal}
        >
          {children}
        </CarouselRowInner>
        {arrowLayout &&
        arrowLayout !== 'top' &&
        (availableArrows.left || availableArrows.right) ? (
          <ArrowButton
            type="button"
            onClick={() => arrowJump('right')}
            disabled={!availableArrows.right}
            arrowLayout={arrowLayout}
            aria-label="Next image"
          >
            <Chevron />
          </ArrowButton>
        ) : null}
      </CarouselRowContainer>
      {thumbnails && thumbnails.length > 1 ? (
        <ThumbnailsGrid
          ref={thumbnailsTrackRef}
          onScroll={() => setThumbnailTrackAutoScrollDisabled(true)}
        >
          {thumbnails.map((thumbnail, index) => (
            <ThumbnailLink
              onClick={() => thumbnailJump(index)}
              type="button"
              key={index}
              aria-label={`Go to image ${index + 1}`}
            >
              {!dottedThumbnailsOnly && (
                <ThumbnailContent
                  active={activeItemIndex === index}
                  ref={
                    activeItemIndex === index ? setActiveThumbnailRefs : null
                  }
                >
                  {thumbnail}
                </ThumbnailContent>
              )}
              <ThumbnailDot
                dottedThumbnailsBreakpoint={dottedThumbnailsBreakpoint}
                active={activeItemIndex === index}
              />
            </ThumbnailLink>
          ))}
        </ThumbnailsGrid>
      ) : null}
    </>
  );
}
