import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { ThumbnailBreakpoints } from 'components/layouts/product/carousel/helpers';
import { tablet, belowDesktop, desktop, mobile } from 'utils/media';
import theme from 'utils/theme';

import { ArrowLayout, CarouselItemProps } from './types';

export const scrollbarHeight = 5;
export const scrollbarPadding = 20;
export const thumbnailWidth = 90;

const genericScrollbarStyles = (inline: boolean) => `
  ::-webkit-scrollbar {
    height: ${scrollbarHeight}px;
    background-color: rgba(255,255,255,0);
  }

  ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
    border: ${scrollbarPadding}px solid rgba(255,255,255,0);
    border-top-width: 0;
    border-bottom-width: 0;
    background-clip: padding-box;
    
    ${tablet} {
      ${
        inline
          ? 'border-left-width: 0; border-right-width: 0;'
          : 'border-left-width: 40px; border-right-width: 40px;'
      }
    }
  }
`;

const interactionScrollbarStyles = (scrollBarReveal: boolean) => `
  ::-webkit-scrollbar-track {
    background-color: ${theme.colors.scrollbarBackground};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.darkGreen};
  }

  ${
    scrollBarReveal
      ? `${desktop} {
        ::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &:hover::-webkit-scrollbar-track {
            background-color: ${theme.colors.scrollbarBackground};
          }
        }`
      : undefined
  }

  ${
    scrollBarReveal
      ? `${desktop} {
          ::-webkit-scrollbar-thumb {
            background-color: transparent;
          }

          &:hover::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.darkGreen};
          }
        }`
      : undefined
  }
`;

interface CarouselRowInnerProps {
  inline?: boolean;
  snap?: boolean;
  scrollBarReveal: boolean;
}

export const CarouselRowInner = styled.div<CarouselRowInnerProps>`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0 20px;
  overscroll-behavior-x: contain;
  scroll-snap-type: ${(props) => (props.snap ? 'x mandatory' : 'none')};
  ${(props) => genericScrollbarStyles(props?.inline ?? false)}
  ${(props) => interactionScrollbarStyles(props.scrollBarReveal)}
`;

export const CarouselRowContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

interface ArrowButtonProps {
  arrowLayout: ArrowLayout;
}

export const ArrowButton = styled.button<ArrowButtonProps>`
  display: none;

  ${desktop} {
    display: flex;
  }

  position: ${(props) =>
    props.arrowLayout === 'overlay' ? 'absolute' : 'static'};
  top: 50%;
  appearance: none;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${theme.zIndexes.mid};
  cursor: pointer;
  color: ${theme.colors.darkGreen};
  width: 37px;
  height: 37px;
  line-height: 0;
  font-size: 0;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  ${({ disabled, arrowLayout }) =>
    disabled
      ? arrowLayout === 'top'
        ? `opacity: 0.5;`
        : `visibility: hidden;`
      : ``}

  ${({ arrowLayout }) =>
    arrowLayout === 'overlay'
      ? `transform: translateY(-50%);`
      : arrowLayout !== 'top'
      ? `margin-top: 46px;`
      : ''}

  svg {
    width: 29px;
    height: 29px;
  }

  &:first-child {
    left: ${(props) => (props.arrowLayout === 'overlay' ? '20px' : '0')};

    svg {
      transform: rotate(90deg);
    }
  }

  &:last-child {
    right: ${(props) => (props.arrowLayout === 'overlay' ? '20px' : '0')};

    svg {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    ${({ arrowLayout }) =>
      arrowLayout !== 'top' ? `background: ${theme.colors.white};` : ''}
  }

  &:focus-visible {
    background: ${theme.colors.white};
    outline: none;
    box-shadow: 0 0 0 var(--space-4xs) ${theme.colors.darkGreen};
  }
`;

export const sizeStyles: { [key: string]: FlattenSimpleInterpolation } = {
  small: css`
    min-width: 132px;
    max-width: 132px;
  `,
  normal: css`
    min-width: 134px;
  `,
  large: css`
    min-width: 150px;
    width: 150px;
    min-width: clamp(150px, 25vw, 290px);
    width: clamp(150px, 30vw, 290px);
    flex-grow: 0;
  `,
  xlarge: css`
    width: 240px;
    min-width: 240px;

    ${tablet} {
      width: 290px;
      min-width: 290px;
    }
  `,
  xxlarge: css`
    width: 240px;
    min-width: 240px;

    ${tablet} {
      width: 400px;
      min-width: 400px;
    }
  `,
  full: css`
    box-sizing: border-box;
    min-width: 100%;
    width: 100%;
    flex-shrink: 0;
    padding: 0 20px;

    ${tablet} {
      padding: 0;
    }
  `,
};

export const CarouselItem = styled.div<CarouselItemProps>`
  box-sizing: content-box;
  flex-shrink: 0;
  padding: 0 var(--space-2xs) 0 var(--space-2xs);
  flex: 1;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;

  ${({ inline }) =>
    inline
      ? css`
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
          ${tablet} {
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        `
      : css`
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
          ${tablet} {
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }
          }
        `}

  ${({ size = 'normal' }) => sizeStyles[size]}
`;

export const ThumbnailsGrid = styled.div`
  margin-top: 20px;
  grid-gap: 15px;
  display: flex;
  justify-content: center;

  ${tablet} {
    /* Right fadeout horizontal scroll effect */
    &:after {
      content: '';
      position: sticky;
      right: 0;
      bottom: 0;
      width: 50px;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.4) 30%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: ${thumbnailWidth}px;
    overflow-x: auto;
    justify-content: start;
    padding-bottom: var(--space-xs);
  }
`;

export const ThumbnailLink = styled.button`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

interface IThumbnailContentProps {
  active: boolean;
}

export const ThumbnailContent = styled.div<IThumbnailContentProps>`
  & > * {
    border: 2px solid transparent;
    transition: border-color 0.1s ease-out;
    ${(props) => props.active && `border-color: ${theme.colors.purple};`}
  }

  ${mobile} {
    display: none;
  }
`;

interface ThumbnailDotProps {
  active: boolean;
  dottedThumbnailsBreakpoint: ThumbnailBreakpoints;
}

const ThumbnailDotBaseStyles = `
  display: inline-block;
  width: var(--space-2xs);
  height: var(--space-2xs);
  padding: 0;
  margin: 0;
  margin-bottom: var(--space-2xs);
  border-radius: 100%;
`;

function getThumbnailDotStyles({
  dottedThumbnailsBreakpoint,
  active,
}: {
  dottedThumbnailsBreakpoint: ThumbnailBreakpoints;
  active: boolean;
}): string {
  switch (dottedThumbnailsBreakpoint) {
    case ThumbnailBreakpoints.Mobile:
      return `
          ${mobile} { 
            ${ThumbnailDotBaseStyles}
            background-color: ${
              active ? theme.colors.darkGreen : theme.colors.scrollbarBackground
            };
          }
        `;
    case ThumbnailBreakpoints.BelowDesktop:
      return `
          ${belowDesktop} { 
            ${ThumbnailDotBaseStyles}
            background-color: ${
              active ? theme.colors.darkGreen : theme.colors.scrollbarBackground
            };
          }
        `;
  }
}

export const ThumbnailDot = styled.div<ThumbnailDotProps>`
  display: none;
  ${({ dottedThumbnailsBreakpoint, active }) =>
    getThumbnailDotStyles({ dottedThumbnailsBreakpoint, active })}
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0 0 var(--space-2xs);
`;
